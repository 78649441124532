import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.internalShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalShow) = $event)),
    color: _ctx.color,
    timeout: 6000,
    top: "",
    right: "",
    "multi-line": ""
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
      _createVNode(_component_v_btn, {
        class: "snackbar-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.internalShow = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Закрыть")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "color"]))
}