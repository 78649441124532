<template>
  <div class="popular-genres">
    <div class="BestGenresPosts">
      <div v-if="genres.length > 0" class="split-container">
        <!-- Первый жанр -->
        <div
            class="popular-genre GenrePost"
            @mouseover="hoverGenre = genres[0]"
            @mouseleave="hoverGenre = null"
        >
          <div class="CountOfGenres">
            <i>({{ genres[0].subgenres.length }})</i>
          </div>
          <img
              :src="genres[0].cover || '/media/images/mockPhotos/temp_genre_cover.jpg'"
              :alt="'Обложка жанра не загружена!'"
              class="centered-image first-image"
          />
          <div class="GenreName">
            <div class="seventeen-font">
              <a :href="'/здесь будут ссылки'">{{ genres[0].name }}</a>
            </div>
          </div>
          <div class="Subgenres">
            <div v-for="subgenre in genres[0].subgenres" :key="subgenre.name">
              <a :href="'/здесь будут ссылки'">{{ subgenre.name }}</a>
            </div>
          </div>
        </div>
        <!-- Остальные жанры -->
        <div class="other-genre-posts">
          <div
              v-for="(genre, index) in genres.slice(1, 4)"
              :key="index"
              class="post GenrePost"
              @mouseover="hoverGenre = genre"
              @mouseleave="hoverGenre = null"
          >
            <div class="CountOfGenres">
              <i>({{ genre.subgenres.length }})</i>
            </div>
            <div class="GenreName">
              <div class="seventeen-font">
                <a :href="'/здесь будут ссылки'">{{ genre.name }}</a>
              </div>
            </div>
            <div class="Subgenres">
              <div v-for="subgenre in genre.subgenres" :key="subgenre.name">
                <a :href="'/здесь будут ссылки'">{{ subgenre.name }}</a>
              </div>
            </div>
          </div>
          <div class="post GenrePost" @click="goToAllGenres">
            <QuarterSquare label="ВСЕ ЖАНРЫ"/>
          </div>
        </div>
      </div>
      <p v-else>Загрузка жанров...</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import QuarterSquare from '@/components/animations/QuarterSquare.vue';
import { components } from 'backend-types';
import { GenreApi } from '@/api/GenreApi';
import router from '@/router/router';

type PopularGenreResponse = components['schemas']['GenreResponse'];
const genreApi = new GenreApi();

export default defineComponent({
  components: {
    QuarterSquare,
  },
  setup() {
    const genres = ref<PopularGenreResponse[]>([]);
    const hoverGenre = ref<PopularGenreResponse | null>(null);

    const fetchPopularGenres = async () => {
      try {
        genres.value = await genreApi.fetchPopularGenres('DESC', 5);
      } catch (error) {
        console.error('Ошибка загрузки популярных жанров:', error);
      }
    };

    const goToAllGenres = () => {
      router.push({ name: 'allGenres' });
    };

    onMounted(() => {
      fetchPopularGenres();
    });

    return {
      genres,
      hoverGenre,
      goToAllGenres,
    };
  },
});
</script>

<style scoped>
/* Стили ссылок */
.GenreName a,
.Subgenres a,
.OtherGenres a {
  text-decoration: none;
  color: inherit;
}

.Subgenres a:hover {
  padding-left: 5px;
  transform: translateY(-1px);
}

/* Основные стили */
.popular-genres {
  width: 100%;
}

.split-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.popular-genre {
  flex: 1 1 50%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  aspect-ratio: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.popular-genre.GenrePost {
  cursor: pointer;
}

.GenrePost {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.post.GenrePost {
  cursor: pointer;
}

.GenrePost:hover {
  background-color: rgba(255, 126, 7, 1);
}

.GenrePost:hover .GenreName,
.GenrePost:hover .Subgenres {
  color: black;
}

.GenrePost:hover .CountOfGenres {
  color: black;
}

.GenrePost:hover .seventeen-font {
  color: black;
}

.GenrePost:hover img.first-image {
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}

/* Изображения */
.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s, border-radius 0.3s;
}

.GenrePost img {
  width: 40%;
  height: auto;
  object-fit: cover;
}

/* Название жанра */
.GenreName {
  padding-bottom: 4%;
  padding-left: 4%;
  align-self: flex-start;
  position: absolute;
  bottom: 25px;
  left: 5px;
  color: rgba(255, 255, 255, 1);
  transition: color 0.3s;
}

.GenrePost:hover .GenreName {
  display: none;
}

.GenreName {
  display: none;
}

/* Количество поджанров */
.CountOfGenres {
  position: absolute;
  top: 5px;
  right: 5px;
  padding-top: 4%;
  padding-right: 4%;
  font-size: 17px;
  color: grey;
  transition: color 0.3s;
}

/* Остальные жанры */
.other-genre-posts {
  flex: 1 1 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.other-genre-posts .post {
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
}

/* Поджанры */
.Subgenres {
  padding-bottom: 4%;
  padding-left: 4%;
  align-self: flex-start;
  position: absolute;
  bottom: 25px;
  left: 5px;
  color: white;
  font-size: 17px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: color 0.3s, transform 0.3s;
  transform: translateY(20px);
  opacity: 0;
}

.GenrePost:hover .Subgenres {
  transform: translateY(0);
  opacity: 1;
}

/* Адаптивность */
@media (max-width: 768px) {
  .popular-genre,
  .other-genre-posts {
    flex-basis: 100%;
    width: 100%;
  }

  .split-container {
    flex-direction: column;
  }

  .other-genre-posts {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .other-genre-posts {
    grid-template-columns: 1fr;
  }
}
</style>
