<template>
  <div class="MainLayout">
    <div class="container">
      <div class="left">Типо картинка</div>
      <div class="form-container">
        <slot></slot>
      </div>
      <div class="right">Инфа о проекте + картинка</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.MainLayout {
  margin: 0 auto;
  max-width: 1760px;
  width: 100%;
  padding: 0 20px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 520px 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1440px;
  align-items: stretch;
}

.left, .right {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 850px;
  max-height: 70vh;
}

.form-container {
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 520px;
  margin: 0 auto;
  align-self: center;
}
</style>
