<template>
  <div class="new-songs">
    <div class="NewSongsPosts">
      <div class="table-header-container">
        <div class="table-header">
          <div class="table-cell">
            <div class="fourteen-font">Название</div>
          </div>
          <div class="table-cell">
            <div class="fourteen-font">Исполнитель</div>
          </div>
          <div class="table-cell">
            <div class="fourteen-font">Сложность</div>
          </div>
        </div>
      </div>
      <div class="table">
        <div
            v-for="(song, index) in newSongs"
            :key="index"
            class="table-row"
            @click="goToSongPage(song.songName, song.authorPseudonym)"
        >
          <div class="table-cell">
            <div class="seventeen-font">{{ song.songName }}</div>
          </div>
          <div class="table-cell">
            <div class="seventeen-font">{{ song.authorPseudonym }}</div>
          </div>
          <div class="table-cell">
            <div class="difficulty">
              <span
                  v-for="star in 5"
                  :key="star"
                  :class="{
                  star: true,
                  'orange-star': star <= song.difficultInStars,
                  'gray-star': star > song.difficultInStars
                }"
              ></span>
              <SongUserCollectionElement :songId="song.id"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import songApi from "@/api/SongApi";
import SongUserCollectionElement from "@/components/song-collection/SongUserCollectionElement.vue";
import {components} from "backend-types";

type SongPreviewResponse = components['schemas']['SongPreviewResponse'];

export default defineComponent({
  components: {
    SongUserCollectionElement
  },
  setup() {
    const newSongs = ref<any[]>([]);
    const router = useRouter();

    const fetchSongs = async () => {
      try {
        const countOfSongs = '15';
        const sortDirection = 'DESC';
        const response = await songApi.fetchSongsOrderedAndLimited(countOfSongs, sortDirection);

        newSongs.value = response.map((song: any) => ({
          id: song.id,
          songName: song.songName,
          authorPseudonym: song.authorPseudonym,
          difficultInStars: song.difficultInStars.length,
          genre: song.subgenre,
          coverTempUrl: song.coverTempUrl,
          addedToCollection: false,
          viewedChords: false,
        }));
      } catch (error) {
        console.error('Error fetching songs:', error);
      }
    };

    const goToSongPage = (songName: string, authorPseudonym: string) => {
      router.push({
        name: 'songView',
        params: {
          songName: songName,
          authorPseudonym: authorPseudonym
        }
      });
    };

    onMounted(() => {
      fetchSongs();
    });

    return {
      newSongs,
      goToSongPage,
    };
  }
});
</script>

<style scoped>
.new-songs {
  width: 100%;
  margin-top: 20px;
}

.NewSongsPosts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-header-container, .table {
  width: 100%;
}

.table-header {
  display: flex;
  width: 100%;
  border: none;
}

.table {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-collapse: collapse;
}

.table-row {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
}

.table-cell {
  padding: 20px;
  text-align: left;
  border: none;
  transition: transform 0.3s, color 0.3s;
}

.table-header > .table-cell {
  border-left: none;
  border-right: none;
}

.table-cell:nth-child(1),
.table-cell:nth-child(2) {
  width: 20%;
}

.table-cell:nth-child(3) {
  text-align: right;
  width: 60%;
}

.table-row:hover {
  background-color: rgba(255, 126, 7, 1);
}

.table-row:hover .table-cell {
  transform: translateX(10px);
  color: black;
}

.table-row:hover .difficulty .star {
  display: none;
}

.table-row:hover .difficulty .dots-wrapper {
  display: flex;
}

.difficulty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.star {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 2px;
}

.orange-star {
  background-color: rgba(255, 126, 7, 1);
}

.gray-star {
  background-color: rgba(136, 136, 136, 1);
}
</style>