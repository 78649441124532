<template>
  <div v-if="song">
    <div class="song-view">
      <p>{{ song.authorPseudonym + " - " + song.name }}</p>
      <div class="song-content">
        <SongWithChordsDisplay :songData="song.text"/>
      </div>
      <div class="chords-images">
      </div>
    </div>
  </div>
  <p v-else-if="loading">Загрузка данных песни...</p>
  <p v-else-if="error">{{ error }}</p>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import songApi from '@/api/SongApi';
import {components} from 'backend-types';
import SongWithChordsDisplay from "@/components/add-song/SongWithChordsDisplay.vue";

type SongResponse = components['schemas']['SongResponse'];
type LinesWithChordsResponse = components['schemas']['LinesWithChordsResponse'];

export default defineComponent({
  components: {
    SongWithChordsDisplay
  },
  setup() {
    const route = useRoute();
    const song = ref<SongResponse | null>(null);
    const loading = ref<boolean>(true);
    const error = ref<string | null>(null);

    const fetchSongData = async () => {
      const songName = route.params.songName as string;
      const authorPseudonym = route.params.authorPseudonym as string;

      try {
        const response = await songApi.fetchSong(songName, authorPseudonym);

        song.value = {
          ...response,
          text: {
            linesWithChords: response.text.linesWithChords.map(
                (lineWithChords: LinesWithChordsResponse, index: number) => ({
                  ...lineWithChords,
                  songBlock: determineSongBlock(index),
                })
            ),
          },
        };
      } catch (err) {
        error.value = 'Ошибка при загрузке данных песни';
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    const determineSongBlock = (index: number): "INTRO" | "VERSE" | "CHORUS" | "BRIDGE" | "END" => {
      if (index === 0) return "INTRO";
      return "VERSE";
    };

    onMounted(() => {
      fetchSongData();
    });

    return {
      song,
      loading,
      error,
    };
  },
});
</script>
