<template>
  <nav class="navbar navbar-light">
    <div class="container">
      <h3>ПОДВАЛЕР</h3>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Footer"
};
</script>