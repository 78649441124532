<template>
  <v-dialog v-model="isAuthorModalOpen" max-width="600px">
    <v-card>
      <v-card-title>Создание нового автора</v-card-title>
      <v-card-text>
        <v-form ref="newAuthorForm" @submit.prevent="saveAuthor">
          <v-text-field v-model="newAuthor.author.name" label="Имя исполнителя"/>
          <v-text-field v-model="newAuthor.author.pseudonym" label="Псевдоним"/>
          <v-text-field v-model="safeYearsOfActivity.start" label="Начало активности"/>
          <v-text-field v-model="safeYearsOfActivity.end" label="Конец активности"/>
          <v-text-field v-model="newAuthor.author.description" label="Описание"/>
          <v-text-field v-model="newAuthor.author.birthDate" label="Дата рождения"/>
          <v-select
              v-model="newAuthor.author.country"
              :items="countries"
              item-value="code"
              item-text="name"
              label="Страна"
          />
          <v-file-input
              v-model="newAuthor.photo"
              label="Фотография"
              accept="image/*"
              @change="previewAuthorPhoto"
          />
          <v-img v-if="authorPhotoPreview" :src="authorPhotoPreview" class="photo-preview"/>
          <v-btn type="submit" class="save-button">Сохранить</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeAuthorModal">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import {
  albums,
  authorPhotoPreview,
  authorsSearchQuery,
  closeAuthorModal,
  errorMessage,
  isAuthorModalOpen,
  newAlbum,
  newAuthor,
  newSong,
  previewImage,
  safeYearsOfActivity,
  selectedAuthor,
  showError,
  showSuccess,
  successMessage,
} from '@/components/add-song/modal/AddSongFormStates';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VFileInput,
  VForm,
  VImg,
  VSelect,
  VTextField
} from "vuetify/components";
import {AxiosError} from "axios";
import {ApiErrorResponse} from "@/api/base/BaseApi";
import authorApi from "@/api/AuthorApi";
import {onMounted} from "vue";

let countries: string[] = [];

const previewAuthorPhoto = () => {
  if (newAuthor.value.photo) {
    previewImage(newAuthor.value.photo, authorPhotoPreview);
  }
};

onMounted(
    () => {
      fetchCountries();
    }
)

const fetchCountries = async () => {
  try {
    countries = await authorApi.fetchAuthorCountries();
  } catch (error) {
    console.error('Ошибка при загрузке списка стран:', error);
  }
};

const saveAuthor = async () => {
  try {
    const response = await authorApi.saveAuthor(newAuthor.value);

    if (response && response.pseudonym) {
      authorsSearchQuery.value = response.pseudonym;
      newAlbum.value.album.authorPseudonym = response.pseudonym;
      selectedAuthor.value = {
        id: response.id,
        pseudonym: response.pseudonym,
      };
      newSong.value.song.authorPseudonym = response.pseudonym;
      albums.value = [];
    }

    successMessage.value = 'Автор успешно создан';
    showSuccess.value = true;
    closeAuthorModal();
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || 'Ошибка при создании автора.';
    showError.value = true;
    console.error('Ошибка при создании автора:', error);
  }
};

</script>

<style scoped>

</style>