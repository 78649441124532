<template>
  <h2>Пожалуйста, авторизуйтесь в своем профиле или создайте новый</h2>

  <Snackbar color="red" v-model:show="showError" :message="errorMessage"/>
  <Snackbar color="green" v-model:show="showSuccess" :message="successMessage"/>

  <form @submit.prevent="login">
    <div class="form-group">
      <input type="text" id="username" v-model="username" placeholder="Номер телефона" @blur="handleBlur('username')"
             @focus="handleFocus('username')" required/>
    </div>
    <div class="form-group">
      <div class="password-container">
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" placeholder="Пароль"
               @blur="handleBlur('password')" @focus="handleFocus('password')" required/>
        <div class="eye-container" @click="togglePassword">
          <div :class="['eye', showPassword ? 'eye-closed' : 'eye-open']"></div>
        </div>
      </div>
      <div class="checkbox-container">
        <v-switch v-model="rememberMe" label="Запомнить меня" class="custom-switch"></v-switch>
        <a href="#" class="forgot-password">Забыли пароль?</a>
      </div>
    </div>
    <button type="submit" class="primary-button">Войти</button>
    <button type="button" class="secondary-button" @click="goToRegister">Создать новый профиль</button>
    <div class="or">Или</div>
    <button type="button" class="vk-button">Войти с помощью VK</button>
  </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Snackbar from '@/components/common/Snackbar.vue';
import AuthApi from "@/api/AuthApi";
import { VSwitch } from 'vuetify/components';
import { CookieManager } from "@/api/base/CookieManager";
import router from "@/router/router";
import { AxiosError } from "axios";
import { ApiErrorResponse } from "@/api/base/BaseApi";

const username = ref<string>('');
const password = ref<string>('');
const showPassword = ref<boolean>(false);
const rememberMe = ref<boolean>(false);

const showError = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const errorMessage = ref<string>('');
const successMessage = ref<string>('');

const authApi = new AuthApi();

const login = async (): Promise<void> => {
  try {
    const { token } = await authApi.signInUser({ username: username.value, password: password.value });

    if (token) {
      handleSuccessfulLogin(token);
    }
  } catch (error: unknown) {
    handleError(error, 'Неизвестная ошибка. Попробуйте снова.');
  }
};

const handleSuccessfulLogin = (token: string): void => {
  CookieManager.setCookie('token', token);
  successMessage.value = 'Вход выполнен успешно';
  showSuccess.value = true;

  setTimeout(() => {
    router.push({ name: 'home' });
  }, 1000);
};

const handleError = (error: unknown, defaultMessage: string): void => {
  const axiosError = error as AxiosError<ApiErrorResponse>;
  errorMessage.value = axiosError.response?.data?.message || defaultMessage;
  showError.value = true;
};

const goToRegister = (): void => {
  router.push({ name: 'register' });
};

const togglePassword = (): void => {
  showPassword.value = !showPassword.value;
};

const handleBlur = (field: string): void => {
  if (!username.value && field === 'username') username.value = '';
  if (!password.value && field === 'password') password.value = '';
};

const handleFocus = (field: string): void => {
  if (field === 'username') username.value = '';
  if (field === 'password') password.value = '';
};
</script>

<style scoped>
.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
}

input {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input::placeholder {
  color: #ccc;
}

.primary-button {
  padding: 15px;
  background-color: #ff7e1b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.primary-button:hover {
  background-color: #e66a00;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid #ff7e1b;
  color: #ff7e1b;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
}

.secondary-button:hover {
  background-color: rgba(255, 126, 27, 0.1);
}

.or {
  text-align: center;
  margin: 20px 0;
  color: #ccc;
}

.vk-button {
  background-color: #4a76a8;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.vk-button:hover {
  background-color: #3b5a77;
}

.password-container {
  display: flex;
  align-items: center;
  position: relative;
}

.eye-container {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.eye {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  background-color: #ff7e1b;
  transition: background-color 0.3s ease;
}

.eye::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.3s ease;
}

.eye-open::before {
  transform: translate(-50%, -50%) scale(0);
}

.eye-closed::before {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.forgot-password {
  color: #ff7e1b;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.custom-switch ::v-deep .v-switch__track {
  background-color: #ff6600;
  opacity: 100%;
  height: 25px;
}

.custom-switch ::v-deep .v-switch__thumb {
  background-color: black;
}
</style>