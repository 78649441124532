export class CookieManager {
    static setCookie(name: string, value: string, days: number = 7, path: string = '/', domain?: string, secure: boolean = false): void {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        let cookie = `${name}=${value}; expires=${expires}; path=${path}`;
        if (domain) {
            cookie += `; domain=${domain}`;
        }
        if (secure) {
            cookie += '; secure';
        }
        document.cookie = cookie;
    }

    static getCookie(name: string): string | undefined {
        const cookies = document.cookie.split('; ').reduce((acc: { [key: string]: string }, cookie) => {
            const [key, value] = cookie.split('=');
            acc[key] = value;
            return acc;
        }, {});

        return cookies[name];
    }

    static deleteCookie(name: string): void {
        CookieManager.setCookie(name, '', -1);
    }
}
