<template>
  <div class="chords-editor">
    <div class="input-field" @click="focusInput" ref="inputWrapper">
      <input
          ref="input"
          v-model="chordsInput"
          @keydown.space.prevent="handleSpace"
          @blur="onBlur"
          @input="onInput"
          class="chords-input"
          type="text"
          :placeholder="showPlaceholder ? 'Введите аккорды...' : ''"
      />
      <div v-if="chords.length" class="chords-container">
        <div
            v-for="(chord, index) in chords"
            :key="index"
            class="chord-wrapper"
            :style="{ left: `${chord.position * getCharWidth()}px` }"
            @mousedown.prevent="startDragging(index, $event)"
        >
          <span class="chord">{{ chord.chord }}</span>
          <span class="delete-chord" @click.stop="removeChord(index)">×</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { components } from 'backend-types';

const props = defineProps<{
  line: { chordsInput: string };
  index: number;
}>();

const emit = defineEmits<{
  (e: 'update-chords', chords: ChordWithPosition[]): void;
}>();

type ChordWithPosition = components['schemas']['ChordWithPosition'];

const chords = ref<ChordWithPosition[]>([]);
const chordsInput = ref<string>(props.line.chordsInput);
const input = ref<HTMLInputElement | null>(null);
const inputWrapper = ref<HTMLDivElement | null>(null);
const isDragging = ref(false);
const dragIndex = ref<number | null>(null);
const dragStartX = ref<number | null>(null);
const chordStartX = ref<number | null>(null);

const showPlaceholder = computed(() => !chordsInput.value && chords.value.length === 0);

const focusInput = () => {
  input.value?.focus();
};

const handleSpace = () => {
  const chord = chordsInput.value.trim();
  if (chord) {
    let position = Math.floor(input.value!.selectionStart || 0);

    if (chords.value.length > 0) {
      const lastChord = chords.value[chords.value.length - 1];
      if (position <= lastChord.position) {
        position = lastChord.position + lastChord.chord.length;
      }
    }
    chords.value.push({ chord, position });
    chordsInput.value = '';
    emit('update-chords', chords.value);
  }
};

const getCharWidth = () => {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'pre';
  span.style.font = getComputedStyle(input.value!).font;
  span.textContent = 'M';
  document.body.appendChild(span);
  const charWidth = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return charWidth;
};

const startDragging = (index: number, event: MouseEvent) => {
  isDragging.value = true;
  dragIndex.value = index;
  dragStartX.value = event.clientX;
  chordStartX.value = chords.value[index].position;
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);
};

const onMouseMove = (event: MouseEvent) => {
  if (!isDragging.value || dragIndex.value === null || !chords.value[dragIndex.value]) return;

  const deltaX = event.clientX - (dragStartX.value || 0);
  const charWidth = getCharWidth();
  const chordLength = chords.value[dragIndex.value].chord.length;
  let newPosition = Math.round((chordStartX.value || 0) + deltaX / charWidth);

  const maxWidth = Math.floor((inputWrapper.value?.clientWidth || 0) / charWidth) - chordLength;

  const leftBoundary = dragIndex.value === 0 ? 0 : chords.value[dragIndex.value - 1].position + chords.value[dragIndex.value - 1].chord.length;
  const rightBoundary = dragIndex.value === chords.value.length - 1 ? maxWidth : chords.value[dragIndex.value + 1].position - chordLength;

  if (newPosition < leftBoundary) {
    let i = dragIndex.value - 1;
    while (i >= 0 && newPosition < chords.value[i].position + chords.value[i].chord.length) {
      i--;
    }
    newPosition = Math.max(newPosition, i >= 0 ? chords.value[i].position + chords.value[i].chord.length : 0);
  } else if (newPosition > rightBoundary) {
    let i = dragIndex.value + 1;
    while (i < chords.value.length && newPosition + chordLength > chords.value[i].position) {
      i++;
    }
    newPosition = Math.min(newPosition, i < chords.value.length ? chords.value[i].position - chordLength : maxWidth);
  }

  chords.value[dragIndex.value].position = newPosition;
  emit('update-chords', chords.value);
};

const onMouseUp = () => {
  isDragging.value = false;
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
};

const onInput = () => {
  emit('update-chords', chords.value);
};

const onBlur = () => {
  if (!isDragging.value) emit('update-chords', chords.value);
};

const removeChord = (index: number) => {
  chords.value.splice(index, 1);
  emit('update-chords', chords.value);
};

watch(chords, (newChords) => {
  if (!isDragging.value) emit('update-chords', newChords);
});

onMounted(() => {
  chordsInput.value = props.line.chordsInput;
});
</script>

<style scoped>
.chords-editor {
  position: relative;
  width: 100%;
}

.input-field {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #444;
  padding: 8px;
  border-radius: 4px;
  background-color: #2c2c2c;
  cursor: text;
}

.chords-input {
  width: 100%;
  border: none;
  background: transparent;
  color: #fff;
  outline: none;
  caret-color: #ff6600;
}

.chords-container {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  pointer-events: none;
}

.chord-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  border: 1px solid #ff6600;
  border-radius: 4px;
  padding: 2px 4px;
  background-color: #2c2c2c;
  cursor: grab;
  user-select: none;
  pointer-events: all;
  transition: left 0.1s;
}

.chord {
  color: #ff6600;
}

.delete-chord {
  margin-left: 4px;
  cursor: pointer;
  color: #ff6600;
  font-weight: bold;
}
</style>
