import {BaseApi} from "@/api/base/BaseApi";
import {components} from "backend-types";

type SongPreviewResponse = components["schemas"]["SongPreviewResponse"];
type SongResponse = components["schemas"]["SongResponse"];
type FretFlexPageOfDataSongPreviewResponse = components["schemas"]["FretFlexPageOfDataSongPreviewResponse"];
type SongSavingRequest = components["schemas"]["SongSavingRequest"];

const API_PREFIX = '/backend'

type SongSavingRequestFormData = {
    photo: File;
    song: Omit<SongSavingRequest, 'photo'>;
};

export class SongApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async fetchSong(songName: string, authorPseudonym: string): Promise<SongResponse> {
        try {
            return this.client.get<SongResponse>(API_PREFIX + '/song', {
                params: {
                    songName,
                    authorPseudonym,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching song: ${error}`);
        }
    }

    public async saveSong(requestBody: SongSavingRequestFormData): Promise<SongResponse> {
        try {
            const formData = new FormData();
            formData.append('photo', requestBody.photo, 'photo.jpg');
            formData.append('song', new Blob([JSON.stringify(requestBody.song)], {type: 'application/json'}));

            return this.client.post<SongResponse>(API_PREFIX + '/song', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw new Error(`Failed to save Album: ${error}`);
        }
    }

    public async fetchSongBlocks(): Promise<[]> {
        try {
            return this.client.get<[]>(API_PREFIX + '/song/blocks', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching song blocks: ${error}`);
        }
    }

    public async fetchSongPreviewsByFirstLetters(
        firstLetterOfSongName: string,
        pageNumber: number,
        pageSize: number,
        columnName: string,
        sortDirection: string
    ): Promise<FretFlexPageOfDataSongPreviewResponse> {
        try {
            const path = API_PREFIX + `/song/all-preview-by/${firstLetterOfSongName}`;
            console.log('Request Path:', path);

            return this.client.get<FretFlexPageOfDataSongPreviewResponse>(path, {
                params: {
                    pageNumber,
                    pageSize,
                    columnName,
                    sortDirection
                },
                headers: {'Content-Type': 'application/json'}
            });
        } catch (error) {
            throw new Error(`Failed to fetch songs page by first letters: ${error}`);
        }
    }

    public async fetchTopSongsByRating(
        countOfSongs: number,
        minRating: number,
        maxRating: number,
        sortDirection: string
    ): Promise<SongPreviewResponse[]> {
        try {
            const path = API_PREFIX + `/song/top-by-rating/${minRating}/${maxRating}/${countOfSongs}`;
            console.log('Request Path:', path);

            const response = this.client.get<SongPreviewResponse[]>(path, {
                params: {sortDirection},
                headers: {'Content-Type': 'application/json'}
            });

            console.log('API Response:', response);
            return response;
        } catch (error) {
            console.error('Failed to fetch top songs by rating:', error);
            throw new Error(`Failed to fetch top songs by rating: ${error}`);
        }
    }

    public async fetchSongsOrderedAndLimited(countOfSongs: string, sortDirection: string): Promise<SongPreviewResponse[]> {
        try {
            return this.client.get<SongPreviewResponse[]>(API_PREFIX + '/song/ordered-and-limited', {
                params: {
                    countOfSongs,
                    sortDirection
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching songs ordered and limited: ${error}`);
        }
    }
}

export default new SongApi();