<template>
  <div class="user-profile">
    <div class="profile-content">
      <div class="profile-left-section">
        <div class="profile-photo">
          <img :src="userInfo.photo || '/default-avatar.png'" alt="User Photo" />
          <button @click="editProfile">Редактировать</button>
        </div>
        <div class="profile-favorite-genre">
          <span class="genre-label">{{ userInfo.favoriteGenre || 'Не указан' }}</span>
        </div>
      </div>
      <div class="profile-details">
        <div class="profile-row">
          <span class="label">Полное имя:</span>
          <span>{{ userInfo.fullName }}</span>
        </div>
        <div class="profile-row">
          <span class="label">Имя пользователя:</span>
          <span>{{ userInfo.username }}</span>
        </div>
        <div class="profile-row">
          <span class="label">Номер телефона:</span>
          <span>{{ userInfo.phone || 'Не указан' }}</span>
        </div>
      </div>
    </div>
    <div class="last-viewed-songs">
      <h2>Последние просмотренные песни:</h2>
      <div class="song-covers">
        <div v-for="(cover, index) in userInfo.lastViewedSongCovers" :key="index" class="song-cover">
          <img :src="cover || '/default-cover.png'" alt="Song Cover" />
        </div>
      </div>
    </div>
    <div class="ru-overlay">RU</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { components } from "user-types";
import { UserApi } from "@/api/UserApi";

const userApi = new UserApi();
type UserInfoResponse = components["schemas"]["UserInfoResponse"];

export default defineComponent({
  setup() {
    const userInfo = ref<UserInfoResponse>({
      fullName: '',
      username: '',
      email: null,
      phone: null,
      favoriteGenre: 'ПОП',
      lastViewedSongCovers: [],
      photo: null,
    });

    const fetchUserInfo = async () => {
      try {
        userInfo.value = await userApi.getUserInfo();
      } catch (error) {
        console.error('Ошибка загрузки данных пользователя:', error);
      }
    };

    const editProfile = () => {
      console.log('Редактировать профиль');
    };

    onMounted(() => {
      fetchUserInfo();
    });

    return {
      userInfo,
      editProfile,
    };
  },
});
</script>

<style scoped>
.user-profile {
  color: #fff;
  background-color: #111;
  padding: 40px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.profile-left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 40%;
}

.profile-photo img {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  object-fit: cover;
}

.profile-photo button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #FF9900;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.profile-photo button:hover {
  background-color: #e68900;
}

.profile-favorite-genre {
  margin-top: 20px;
  font-size: 48px;
  font-weight: bold;
  color: #FF9900;
  text-align: center;
}

.profile-details {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 18px;
}

.profile-row .label {
  font-weight: bold;
  color: #aaa;
}

.profile-row {
  color: #FF9900;
  font-weight: bold;
}

.last-viewed-songs {
  margin-top: 30px;
}

h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.song-covers {
  display: flex;
  gap: 20px;
}

.song-cover img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.ru-overlay {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 160px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.05);
  z-index: -1;
  transform: translate(20%, -50%);
}
</style>
