import { BaseApi } from "@/api/base/BaseApi";
import { components } from "user-types";
import { CookieManager } from "@/api/base/CookieManager";

type RegistrationRequest = components["schemas"]["UserRegistrationRequest"];
type JwtAuthenticationResponse = components["schemas"]["JwtAuthenticationResponse"];
type UserSignInRequest = components["schemas"]["UserSignInRequest"];

const API_PREFIX = '/auth';

export default class AuthApi {
    private client = new BaseApi();

    async signUpUser(requestBody: { photo: File; user: Omit<RegistrationRequest, 'photo'> }): Promise<JwtAuthenticationResponse> {
        const formData = new FormData();
        formData.append('photo', requestBody.photo, 'photo.jpg');
        formData.append('user', new Blob([JSON.stringify(requestBody.user)], { type: 'application/json' }));
        return this.client.post(API_PREFIX + '/sign-up', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    async signInUser(requestBody: UserSignInRequest): Promise<JwtAuthenticationResponse> {
        const response = await this.client.post<JwtAuthenticationResponse>(API_PREFIX + '/sign-in', requestBody);
        if (response.token) CookieManager.setCookie('token', response.token);
        return response;
    }
}
