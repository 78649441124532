import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0071e733"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dots-wrapper" }
const _hoisted_2 = { class: "dots-container" }
const _hoisted_3 = { class: "collections-title" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "collections-dropdown"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (dot) => {
        return _createElementVNode("span", {
          class: "dot",
          key: dot
        })
      }), 64)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userCollections, (collection) => {
          return (_openBlock(), _createElementBlock("div", {
            key: collection.id,
            class: "collection-item",
            onClick: _withModifiers(($event: any) => (_ctx.addToCollection(collection.id)), ["stop"])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["plus-icon", {'checked-icon': _ctx.isAddedToCollection(collection.id)}])
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(collection.title), 1)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    (_ctx.showCollections)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userCollections, (collection) => {
            return (_openBlock(), _createElementBlock("div", {
              key: collection.id,
              class: "collection-item",
              onClick: _withModifiers(($event: any) => (_ctx.addToCollection(collection.id)), ["stop"])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["plus-icon", {'checked-icon': _ctx.isAddedToCollection(collection.id)}])
              }, "+", 2),
              _createTextVNode(" " + _toDisplayString(collection.title), 1)
            ], 8, _hoisted_6))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}