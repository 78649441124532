<template>
  <v-container class="add-song-container">
    <Snackbar :color="'red'" :show="showError" :message="errorMessage" @update:show="showError = $event"/>
    <Snackbar :color="'green'" :show="showSuccess" :message="successMessage" @update:show="showSuccess = $event"/>

    <v-row class="top-bar" align="center" justify="space-between">
      <v-col class="step-section">
        <v-chip class="step-display">{{ currentStepTitle }}</v-chip>
      </v-col>
      <v-col class="button-section" cols="auto">
        <v-btn @click="goToPreviousStep" :disabled="isFirstStep" class="back-button">Назад</v-btn>
        <v-btn @click="goToNextStep" :disabled="!isNextStepActive" class="forward-button">
          {{ isLastStep ? 'Сохранить' : 'Далее' }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="form-section" align="center" justify="center">
      <v-col cols="12" class="input-block">
        <div v-if="currentStep === 0">
          <v-text-field v-model="authorsSearchQuery" label="Автор" placeholder="Введите имя автора" clearable
                        append-inner-icon="mdi-magnify" @input="onAuthorInputChange" class="search-field"/>
          <v-list v-if="authors.length" class="author-list">
            <v-list-item v-for="author in authors" :key="author.id" @click="selectAuthor(author)" class="author-item">
              <v-list-item-title class="author-item-title">{{ author.pseudonym }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-btn v-if="!authors.length && !selectedAuthor && authorsSearchQuery" @click="openAuthorModal"
                 class="create-author-button">Создать автора
          </v-btn>
          <v-text-field v-model="albumsSearchQuery" label="Альбом" :disabled="!selectedAuthor"
                        @input="onAlbumInputChange" class="album-field"/>
          <v-list v-if="albums.length" class="album-list">
            <v-list-item v-for="album in albums" :key="album.id" @click="selectAlbum(album)" class="album-item">
              <v-list-item-title class="album-item-title">{{ album.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-btn v-if="selectedAuthor && !albums.length && !selectedAlbum && albumsSearchQuery" @click="openAlbumModal"
                 class="create-album-button">Создать альбом
          </v-btn>
          <v-text-field v-model="newSong.song.name" label="Название песни" class="song-field"/>
          <v-text-field v-model="newSong.song.tempoBPM" label="Темп (BPM)" class="song-field"/>
          <v-select v-model="newSong.song.subgenre" :items="formattedSubgenres" label="Жанр"
                    :loading="!formattedSubgenres.length" :disabled="!formattedSubgenres.length" class="song-field"/>
          <v-text-field v-model="newSong.song.difficultInStars" label="Уровень сложности" @input="validateAndCleanStars"
                        :error-messages="difficultInStarsError" class="song-field"/>
          <v-text-field v-model="newSong.song.sampleYoutubeLink" label="Ссылка на видео кавер песни" class="song-field"/>
          <v-file-input v-model="newSong.photo" label="Обложка песни" accept="image/*" @change="previewSongPhoto" class="song-field"/>
          <v-img v-if="songPhotoPreview" :src="songPhotoPreview" class="photo-preview"/>
        </div>

        <div v-if="currentStep === 1">
          <v-row class="form-section" align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="newSong.song.description" label="Описание песни" class="song-field"/>
              <v-btn class="add-remove-button" @click="addSongLine">+</v-btn>
              <v-btn class="add-remove-button" @click="removeSongLine">-</v-btn>
              <v-form ref="chordsForm" @submit.prevent="saveSong">
                <v-row v-for="(line, index) in newSongLines" :key="index" class="line-row">
                  <v-col cols="1">
                    <v-btn class="block-selector" @click="toggleSongBlock(index)">{{ line.songBlock }}</v-btn>
                  </v-col>
                  <v-col cols="11">
                    <ChordsEditor :line="line" :index="index" @update-chords="updateChordPositions(line, $event)"/>
                    <v-text-field v-model="line.line" label="Строка текста песни" class="song-line-field"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <AuthorModalForm/>
    <AlbumModalForm/>
  </v-container>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import {
  VBtn,
  VChip,
  VCol,
  VContainer,
  VFileInput,
  VForm,
  VImg,
  VList,
  VListItem,
  VListItemTitle,
  VRow,
  VSelect,
  VTextField
} from 'vuetify/components';
import ChordsEditor from "@/components/add-song/ChordsEditor.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import {
  addSongLine,
  albums,
  albumsSearchQuery,
  authors,
  authorsSearchQuery,
  currentStep,
  currentStepTitle,
  errorMessage,
  fetchSongBlocks,
  fetchSubgenres,
  genres,
  goToNextStep,
  goToPreviousStep,
  isFirstStep,
  isLastStep,
  isNextStepActive,
  newSong,
  newSongLines,
  onAlbumInputChange,
  onAuthorInputChange,
  openAlbumModal,
  openAuthorModal,
  previewImage,
  removeSongLine,
  saveSong,
  selectAlbum,
  selectAuthor,
  selectedAlbum,
  selectedAuthor,
  showError,
  showSuccess,
  songPhotoPreview,
  successMessage,
  toggleSongBlock,
  updateChordPositions
} from "@/components/add-song/modal/AddSongFormStates";
import AuthorModalForm from "@/components/add-song/modal/AuthorModalForm.vue";
import AlbumModalForm from "@/components/add-song/modal/AlbumModalForm.vue";

onMounted(() => {
  fetchSongBlocks();
  fetchSubgenres();
});

const previewSongPhoto = () => {
  if (newSong.value.photo) {
    previewImage(newSong.value.photo, songPhotoPreview);
  }
};

const difficultInStarsError = ref<string | null>(null);

const validateAndCleanStars = () => {
  let value = newSong.value.song.difficultInStars;
  const cleanedValue = value.replace(/[^*]/g, '');
  if (cleanedValue !== value) {
    newSong.value.song.difficultInStars = cleanedValue;
  }
  difficultInStarsError.value = cleanedValue.length >= 1 && cleanedValue.length <= 5 ? null : 'Поле должно содержать от 1 до 5 звездочек';
};

const formattedSubgenres = computed(() => {
  if (!genres.value.length) {
    console.error("Данные genres не загружены");
    return [];
  }
  return genres.value.flatMap((genre) => {
    if (!genre.subgenres || !genre.subgenres.length) {
      console.warn(`Нет поджанров для жанра: ${genre.name}`);
      return [];
    }
    return genre.subgenres.map((subgenre) => subgenre.name);
  });
});
</script>

<style scoped>
.add-song-container {
  max-width: 1760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #121212;
}

.top-bar {
  background-color: #1e1e1e;
  padding: 10px;
  height: 70px;
  color: #ffffff;
}

.step-section {
  flex-grow: 1;
  color: #ffffff;
}

.button-section {
  display: flex;
  gap: 10px;
}

.form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
}

.input-block {
  width: 60%;
}

.search-field,
.album-field,
.song-field {
  width: 100%;
  height: 50px;
  background-color: #2c2c2c;
  color: #ffffff;
  margin-bottom: 12px; /* Add this line */
}

.search-field:focus,
.album-field:focus,
.song-field:focus {
  border-color: #ff6600;
}

.author-list,
.album-list {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
  padding: 0;
  list-style: none;
  background-color: #2c2c2c;
  border: 1px solid #444444;
  border-radius: 4px;
}

.author-item,
.album-item {
  color: #ffffff;
  cursor: pointer;
  background-color: #444444;
  padding: 12px;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
}

.author-item:hover,
.album-item:hover {
  background-color: #555555;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.author-item-title,
.album-item-title {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}

.photo-preview {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #2c2c2c;
  border: 1px solid #444444;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff6600;
}

.forward-button,
.back-button,
.create-author-button,
.create-album-button {
  width: 100px;
  color: #ffffff;
  background-color: #ff6600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border-radius: 4px;
}

.block-selector {
  color: #ff6600;
  width: 100px;
  height: 50px;
  background-color: #2c2c2c;
  border: 1px solid #444444;
}

.song-line-field {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #444444;
  width: calc(100% - 20px);
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.line-row {
  margin-bottom: 20px;
}

.add-remove-button {
  color: #ff6600;
  width: 50px;
  height: 50px;
  background-color: #333333;
  border: 1px solid #444444;
  border-radius: 4px;
}
</style>