import {BaseApi} from "@/api/base/BaseApi";
import {components} from "backend-types";

type AddSongToUserCollectionResponse = components["schemas"]["AddSongToUserCollectionResponse"]
type GetUserCollectionResponse = components["schemas"]["GetUserCollectionResponse"]

const API_PREFIX = '/backend/collection/';

export class CollectionApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async getUserCollections(limit: number): Promise<GetUserCollectionResponse[]> {
        try {
            return this.client.get<GetUserCollectionResponse[]>(API_PREFIX + `all/${limit}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`Не удалось получить коллекции пользователя: ${error}`);
        }
    }

    public async addSongToCollection(collectionId: string, songId: string): Promise<AddSongToUserCollectionResponse> {
        try {
            return await this.client.post<AddSongToUserCollectionResponse>(API_PREFIX + `add/${songId}/to/${collectionId}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`Не удалось добавить песню в пользовательскую коллекцию: ${error}`);
        }
    }

    public async removeSongFromCollection(collectionId: string, songId: string): Promise<AddSongToUserCollectionResponse> {
        try {
            return await this.client.post<AddSongToUserCollectionResponse>(API_PREFIX + `remove/${songId}/from/${collectionId}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`Не удалось удалить песню из пользовательской коллекции: ${error}`);
        }
    }

}

export default new CollectionApi();
