<template>
  <v-dialog v-model="isAlbumModalOpen" max-width="600px">
    <v-card>
      <v-card-title>Создание нового альбома</v-card-title>
      <v-card-text>
        <v-form ref="newAlbumForm" @submit.prevent="saveAlbum">
          <v-text-field v-model="newAlbum.album.name" label="Название альбома"/>
          <v-text-field v-model="newAlbum.album.description" label="Описание альбома"/>
          <v-text-field v-model="newAlbum.album.release" label="Дата релиза"/>
          <v-file-input
              v-model="newAlbum.photo"
              label="Фотография"
              accept="image/*"
              @change="previewAlbumPhoto"
          />
          <v-img v-if="albumPhotoPreview" :src="albumPhotoPreview" class="photo-preview"/>
          <v-btn type="submit" class="save-button">Сохранить</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeAlbumModal">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VFileInput,
  VForm,
  VImg,
  VTextField
} from "vuetify/components";
import {
  albumPhotoPreview,
  albums,
  albumsSearchQuery,
  errorMessage,
  isAlbumModalOpen,
  newAlbum,
  newSong,
  previewImage,
  selectedAlbum,
  showError,
  showSuccess,
  successMessage,
} from "@/components/add-song/modal/AddSongFormStates";
import {AxiosError} from "axios";
import {ApiErrorResponse} from "@/api/base/BaseApi";
import albumApi from "@/api/AlbumApi";


const saveAlbum = async () => {
  try {
    const response = await albumApi.saveAlbum(newAlbum.value);

    if (response && response.name) {
      albumsSearchQuery.value = response.name;
      selectedAlbum.value = {
        ...newAlbum.value.album,
        id: response.id,
        name: response.name,
      };
      newSong.value.song.albumName = response.name;
      albums.value = [];
    }

    successMessage.value = 'Альбом успешно создан';
    showSuccess.value = true;
    closeAlbumModal();
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || 'Ошибка при создании альбома.';
    showError.value = true;
    console.error('Ошибка при создании альбома:', error);
  }
};

const closeAlbumModal = () => {
  isAlbumModalOpen.value = false;
};


const previewAlbumPhoto = () => {
  if (newAlbum.value.photo) {
    previewImage(newAlbum.value.photo, albumPhotoPreview);
  }
};

</script>

<style scoped>

</style>