import {BaseApi} from "@/api/base/BaseApi";
import {components} from "user-types";

const API_PREFIX = '/user'

type UserInfoResponse = components["schemas"]["UserInfoResponse"]

export class UserApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async getUserInfo(): Promise<UserInfoResponse> {
        try {
            return this.client.get<UserInfoResponse>(API_PREFIX + '/info', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            throw new Error(`Failed to get user info: ${error}`);
        }
    }
}

export default new UserApi();
