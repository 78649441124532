import {BaseApi} from "@/api/base/BaseApi";
import {components} from "backend-types";

type AuthorSavingRequest = components["schemas"]["AuthorSavingRequest"]
type AuthorResponse = components["schemas"]["AuthorResponse"]
type AuthorInfoResponse = components["schemas"]["AuthorInfoResponse"]
type FretFlexPageOfDataAuthorPreviewResponse = components["schemas"]["FretFlexPageOfDataAuthorPreviewResponse"]

type AuthorSavingRequestFormData = {
    photo: File;
    author: Omit<AuthorSavingRequest, 'photo'>;
};

const API_PREFIX = '/backend'

export class AuthorApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }


    public async fetchAuthorCountries(): Promise<[]> {
        try {
            return await this.client.get<[]>(API_PREFIX + '/author/countries', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching author countries: ${error}`);
        }
    }

    public async fetchAuthorsByFirstLetters(authorFirstLetters: string, limit: number): Promise<components["schemas"]["AuthorDynamicPreviewResponse"][]> {
        try {
            return this.client.get<components["schemas"]["AuthorDynamicPreviewResponse"][]>(API_PREFIX + `/author/by-first-letters-of/${authorFirstLetters}`, {
                params: {limit},
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching authors by first letters: ${error}`);
        }
    }

    public async saveAuthor(requestBody: AuthorSavingRequestFormData): Promise<AuthorResponse> {
        try {
            const formData = new FormData();
            formData.append('photo', requestBody.photo, 'photo.jpg');
            formData.append('author', new Blob([JSON.stringify(requestBody.author)], {type: 'application/json'}));

            return this.client.post<AuthorResponse>(API_PREFIX + '/author', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw new Error(`Failed to save author: ${error}`);
        }
    }

    public async fetchAuthorInfoByPopularity(amountOfData: number): Promise<AuthorInfoResponse[]> {
        try {
            return this.client.get<AuthorInfoResponse[]>(API_PREFIX + `info-by-popularity/${amountOfData}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            throw new Error(`Failed to fetch authors info by popularity: ${error}`);
        }
    }

    public async fetchAuthorByPseudonym(pseudonym: string): Promise<AuthorResponse> {
        try {
            return this.client.get<AuthorResponse>(API_PREFIX + `${pseudonym}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            throw new Error(`Failed to fetch authors by pseudonym: ${error}`);
        }
    }

    public async getAllAuthorPreviewByFirstLetterOfPseudonymOrRealName(
        firstLettersOfPseudonymOrRealName: string,
        pageNumber: number,
        pageSize: number,
        sortColumnName: string,
        sortDirection: string,
    ): Promise<FretFlexPageOfDataAuthorPreviewResponse> {
        try {
            return this.client.get<FretFlexPageOfDataAuthorPreviewResponse>(API_PREFIX + `preview-page`, {
                params: {
                    firstLettersOfPseudonymOrRealName,
                    pageNumber,
                    pageSize,
                    sortColumnName,
                    sortDirection
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            throw new Error(`Failed to fetch authors preview page: ${error}`);
        }
    }


}

export default new AuthorApi();
