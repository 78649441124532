<template>
  <div class="dots-wrapper">
    <div class="dots-container">
      <span class="dot" v-for="dot in 3" :key="dot"></span>
      <div class="collections-title">
        <div v-for="collection in userCollections" :key="collection.id" class="collection-item"
             @click.stop="addToCollection(collection.id)">
          <span
              class="plus-icon"
              :class="{'checked-icon': isAddedToCollection(collection.id)}"
          ></span>
          {{ collection.title }}
        </div>
      </div>
    </div>
    <div v-if="showCollections" class="collections-dropdown">
      <div v-for="collection in userCollections" :key="collection.id" class="collection-item"
           @click.stop="addToCollection(collection.id)">
        <span
            class="plus-icon"
            :class="{'checked-icon': isAddedToCollection(collection.id)}"
        >&#43;</span>
        {{ collection.title }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted} from 'vue';
import collectionApi from "@/api/CollectionApi";
import {components} from "backend-types";

type GetUserCollectionResponse = components['schemas']['GetUserCollectionResponse'];

export default defineComponent({
  props: {
    songId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const userCollections = ref<GetUserCollectionResponse[]>([]);
    const showCollections = ref(false);
    const addedCollections = ref<Set<string>>(new Set());

    const fetchUserCollections = async () => {
      try {
        userCollections.value = await collectionApi.getUserCollections(5);
      } catch (error) {
        console.error('Error fetching user collections:', error);
      }
    };

    const addToCollection = async (collectionId: string) => {
      try {
        await collectionApi.addSongToCollection(collectionId, props.songId);
        addedCollections.value.add(collectionId);
      } catch (error) {
        console.error('Error adding song to collection:', error);
      }
    };

    const isAddedToCollection = (collectionId: string) => {
      return addedCollections.value.has(collectionId);
    };

    onMounted(() => {
      fetchUserCollections();
    });

    return {
      userCollections,
      showCollections,
      addedCollections,
      addToCollection,
      isAddedToCollection,
    };
  }
});
</script>

<style scoped>
.dots-wrapper {
  display: none;
}

.dots-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  transition: transform 0.3s;
}

.dots-container:hover {
  transform: scale(1.1);
}

.dot {
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  margin: 0 2px;
  transition: background-color 0.3s;
}

.dots-container:hover .dot {
  background-color: rgba(255, 126, 7, 1);
}

.collections-title {
  position: absolute;
  top: 4%;
  transform: translateX(-100%);
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 40%;
  z-index: 1;
  display: none;
  width: max-content;
}

.dots-container:hover .collections-title {
  display: block;
}

.collections-dropdown {
  position: relative;
  top: 100%;
  background-color: white;
  border-radius: 5px;
}

.collection-item {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.collection-item:hover {
  transform: scale(1.10);
  background-color: rgba(255, 126, 7, 1);
}

.plus-icon {
  color: white;
  margin-right: 10px;
  cursor: pointer;
  transition: content 0.3s;
  display: inline-block;
}

.plus-icon.checked-icon::before {
  content: '\2713';
}

.plus-icon::before {
  content: '\002B';
}
</style>