<template>
  <v-snackbar v-model="internalShow" :color="color" :timeout="6000" top right multi-line>
    {{ message }}
    <v-btn class="snackbar-button" @click="internalShow = false">Закрыть</v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {VBtn, VSnackbar} from 'vuetify/components';

export default defineComponent({
  props: {
    color: {
      type: String as PropType<string>,
      required: true
    },
    show: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    message: {
      type: String as PropType<string>,
      required: true
    }
  },
  emits: ['update:show'],
  components: {
    VSnackbar,
    VBtn
  },
  data() {
    return {
      internalShow: this.show
    };
  },
  watch: {
    show(newVal) {
      this.internalShow = newVal;
    },
    internalShow(newVal) {
      this.$emit('update:show', newVal);
    }
  }
});
</script>

<style scoped>
.snackbar-button {
  color: white;
  background-color: #ff6600;
}
</style>
