<template>
  <div class="popular-authors">
    <div class="bestAuthorsPosts">
      <div v-if="authors.length > 0" class="author-container">
        <div
            v-for="(author, index) in authors.slice(0, maxVisibleAuthors)"
            :key="index"
            class="author-post"
            @mouseover="hoverAuthor = author"
            @mouseleave="hoverAuthor = null"
        >
          <div class="author-square">
            <div class="background-overlay"></div>
            <div class="image-container">
              <img :src="author.authorPhoto" alt="Популярные авторы не загружены!" class="centered-image" />
            </div>
            <div v-if="hoverAuthor === author" class="author-description">
              <div class="author-name">{{ author.authorPseudonym }}</div>
              <div class="description">{{ author.description }}</div>
            </div>
          </div>
        </div>
        <div class="author-post all-authors">
          <div class="all-authors-content">
            <QuarterSquare label="ВСЕ ИСПОЛНИТЕЛИ" />
          </div>
        </div>
      </div>
      <p v-else>Загрузка авторов...</p>
    </div>
  </div>
</template>

<script>
import QuarterSquare from '@/components/animations/QuarterSquare.vue';

export default {
  components: {
    QuarterSquare,
  },
  data() {
    return {
      maxVisibleAuthors: 7,
      hoverAuthor: null,
      authors: [
        {
          authorPseudonym: 'Баста',
          authorPhoto: '/media/images/mockPhotos/basta_artist.jpg',
          authorRating: 1,
          description: 'Один из самых влиятельных рэп-исполнителей и продюсеров России, соединяющий хип-хоп с философией жизни.',
        },
        {
          authorPseudonym: 'Виктор Цой',
          authorPhoto: '/media/images/mockPhotos/tsoy_artist.jpg',
          authorRating: 2,
          description: 'Легендарный лидер группы "Кино", символ целого поколения, чьи песни о свободе и смысле жизни остаются актуальными.',
        },
        {
          authorPseudonym: 'Юрий Шевчук',
          authorPhoto: '/media/images/mockPhotos/tsoy_artist.jpg',
          authorRating: 3,
          description: 'Фронтмен группы "ДДТ", известный своими глубокими текстами о социальных и политических темах, а также философией жизни.',
        },
        {
          authorPseudonym: 'Константин Кинчев',
          authorPhoto: '/media/images/mockPhotos/tsoy_artist.jpg',
          authorRating: 4,
          description: 'Лидер "Алисы", культовой рок-группы, чьи песни наполнены протестом и внутренней силой.',
        },
        {
          authorPseudonym: 'Сергей Шнуров',
          authorPhoto: '/media/images/mockPhotos/basta_artist.jpg',
          authorRating: 5,
          description: 'Фронтмен группы "Ленинград", известен своими эпатажными выступлениями и острыми текстами, затрагивающими жизнь простых людей.',
        },
        {
          authorPseudonym: 'Андрей Макаревич',
          authorPhoto: '/media/images/mockPhotos/tsoy_artist.jpg',
          authorRating: 6,
          description: 'Основатель группы "Машина времени", один из пионеров русского рока, чьи песни наполнены душевностью и лиричностью.',
        },
        {
          authorPseudonym: 'Илья Лагутенко',
          authorPhoto: '/media/images/mockPhotos/tsoy_artist.jpg',
          authorRating: 7,
          description: 'Фронтмен группы "Мумий Тролль", известный своим уникальным стилем и эклектичными текстами, соединяющими рок и поп-культуру.',
        },
        {
          authorPseudonym: 'Земфира',
          authorPhoto: '/media/images/mockPhotos/tsoy_artist.jpg',
          authorRating: 8,
          description: 'Одна из самых значимых рок-исполнительниц, известная своими эмоциональными песнями и уникальной манерой исполнения.',
        },
      ],
    };
  },
};
</script>


<style scoped>
.popular-authors {
  width: 100%;
}

.bestAuthorsPosts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.author-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;
  width: 100%;
  box-sizing: border-box;
}

.author-post {
  position: relative;
  aspect-ratio: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: background-color 0.5s ease;
}

.author-square {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff7e07;
  transform: scale(0);
  transform-origin: center;
  transition: transform 0.7s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.author-post:hover .background-overlay {
  transform: scale(1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 30%;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.author-post:hover .centered-image {
  transform: translate(-50%, -50%) scale(0.3);
  opacity: 0;
}

.author-description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: left;
  color: black;
}

.author-name {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}

.description {
  font-size: 1.2rem;
  margin-top: 10px;
  color: black;
  line-height: 1.5;
}

.author-post:hover .author-description {
  transform: translate(-50%, -50%) scale(1);
}

.author-post:hover {
  background-color: rgba(255, 126, 7, 1);
}

.all-authors {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.all-authors-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
