<template>
  <div class="song-display">
    <div class="song-content">
      <div v-for="(lineWithChords, index) in linesWithChords" :key="index" class="song-line-block">
        <div class="chords-line">
          <pre class="chords-text">{{ getChordsLine(lineWithChords.chordWithPositions) }}</pre>
        </div>
        <div class="text-line">
          {{ lineWithChords.line }}
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="chord-images-container">
      <div v-for="(chordImage, index) in uniqueChords" :key="index" class="chord-image">
        <img :src="chordImage.fingeringTempUrl" :alt="chordImage.chord" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { components } from 'backend-types';

type TextOfSongResponse = {
  linesWithChords: components['schemas']['LinesWithChordsResponse'][];
};

type LinesWithChordsResponse = {
  songBlock: 'INTRO' | 'VERSE' | 'CHORUS' | 'BRIDGE' | 'END';
  line: string;
  chordWithPositions: ChordWithPositionResponse[];
};

type ChordWithPositionResponse = {
  chord: string;
  fingeringTempUrl: string;
  position: number;
};

const props = defineProps<{ songData: TextOfSongResponse }>();

const linesWithChords = ref<LinesWithChordsResponse[]>(props.songData.linesWithChords);
const charWidth = ref<number>(0);

const uniqueChords = computed(() => {
  const chordMap = new Map<string, ChordWithPositionResponse>();
  linesWithChords.value.forEach(line => {
    line.chordWithPositions.forEach(chord => {
      if (!chordMap.has(chord.chord)) {
        chordMap.set(chord.chord, chord);
      }
    });
  });
  return Array.from(chordMap.values());
});

const getCharWidth = () => {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'pre';
  span.style.font = '16px Arial';
  span.textContent = 'M';
  document.body.appendChild(span);
  const width = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return width;
};

const getChordsLine = (chordWithPositions: ChordWithPositionResponse[]): string => {
  let chordsLine = '';
  const maxPosition = chordWithPositions.reduce((max, chord) => Math.max(max, chord.position), 0);

  for (let i = 0; i <= maxPosition; i++) {
    const chordAtPosition = chordWithPositions.find((chord) => chord.position === i);
    if (chordAtPosition) {
      chordsLine += chordAtPosition.chord;
    } else {
      chordsLine += ' ';
    }
  }

  return chordsLine;
};

onMounted(() => {
  charWidth.value = getCharWidth();
});
</script>

<style scoped>
.song-display {
  display: flex;
  background-color: #121212;
  padding: 20px;
  width: 100%;
  height: 70vh;
  border: 1px solid #333;
}

.song-content {
  flex: 8;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.song-line-block {
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.chords-line {
  position: relative;
  min-height: 20px;
  font-size: 16px;
  color: #ffa500;
  font-family: Arial, sans-serif;
  margin-bottom: 5px;
}

.text-line {
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #ffffff;
  margin-bottom: 15px;
}

.chords-text {
  font-weight: bold;
  white-space: pre;
}

.divider {
  width: 1px;
  background-color: #333;
  margin: 0 20px;
}

.chord-images-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: calc(70vh - 40px);
  padding-left: 10px;
}

.chord-image {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.chord-image img {
  width: auto;
  height: auto;
  max-width: 100%;
}
</style>
