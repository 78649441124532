<template>
  <div
      class="square-container"
      @mouseover="startAnimation"
      @mouseleave="resetAnimation"
  >
    <div
        class="square-icon"
        v-for="index in 4"
        :key="index"
        :style="{
        zIndex: zOrder[index - 1],
        transform: `translate(${translateX[index - 1]}px, ${translateY[index - 1]}px) scale(0.8)`
      }"
    >
      <img src="../../assets/icons/svg/rectangle.svg" alt="Квадратик" class="centered-image" />
    </div>
    <div class="arrow-icon">
      <img src="../../assets/icons/svg/orange_arrow.svg" alt="Стрелка" />
    </div>
    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'QuarterSquare',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      translateX: [50, -50, 50, -50],  // Используем пиксели для точного контроля
      translateY: [-50, -50, 50, 50],  // Используем пиксели для точного контроля
      zOrder: [0, 1, 2, 3],
      isAnimating: false,
    };
  },
  methods: {
    startAnimation() {
      if (!this.isAnimating) {
        this.isAnimating = true;
        this.translateX = [-50, -50, 50, 50];
        this.translateY = [50, -50, -50, 50];
        this.zOrder = [3, 0, 1, 2];
      }
    },
    resetAnimation() {
      if (this.isAnimating) {
        this.isAnimating = false;
        this.translateX = [50, -50, 50, -50];
        this.translateY = [-50, -50, 50, 50];
        this.zOrder = [0, 1, 2, 3];
      }
    }
  }
};
</script>

<style scoped>
.square-container {
  width: 100%; /* Задаем фиксированную ширину, чтобы контейнер всегда был в пределах */
  height: 100%; /* Задаем фиксированную высоту */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Применяем overflow: hidden для защиты от выхода за пределы */
}

.square-icon {
  width: 25%; /* Контролируем размеры квадратиков */
  height: 25%;
  position: absolute;
  transition: transform 0.3s ease;
}

.centered-image {
  width: 100%;
  height: 100%;
}

.arrow-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.arrow-icon img {
  width: 100%;
  height: 100%;
}

.square-container:hover .arrow-icon {
  transform: rotate(-180deg);
  filter: brightness(0) saturate(100%);
}

.label {
  position: absolute;
  bottom: 25px;
  left: 10px;
  color: rgba(255, 126, 7, 1);
  transition: color 0.3s, transform 0.3s;
}

.square-container:hover .label {
  color: black;
  transform: translateX(5px);
}
</style>
