<template>
  <h2>Пожалуйста, заполните необходимые поля для создания нового профиля</h2>

  <Snackbar color="red" v-model:show="showError" :message="errorMessage"/>
  <Snackbar color="green" v-model:show="showSuccess" :message="successMessage"/>

  <form @submit.prevent="register">
    <div class="form-group">
      <input type="text" id="firstname" v-model="form.firstname" placeholder="Имя"/>
    </div>
    <div class="form-group">
      <input type="text" id="lastname" v-model="form.lastname" placeholder="Фамилия"/>
    </div>
    <div class="form-group">
      <input type="text" id="surname" v-model="form.surname" placeholder="Отчество"/>
    </div>
    <div class="form-group">
      <input type="text" id="username" v-model="form.username" placeholder="Имя пользователя" required/>
    </div>
    <div class="form-group">
      <div class="password-container">
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="form.password" placeholder="Пароль" required/>
        <div class="eye-container" @click="togglePassword">
          <div :class="['eye', showPassword ? 'eye-closed' : 'eye-open']"></div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <input :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="form.confirmPassword" placeholder="Подтвердить пароль" required/>
    </div>
    <div v-if="passwordMismatch" class="error-message">Пароли не совпадают</div>

    <div class="form-group">
      <input type="email" id="email" v-model="form.email" placeholder="Email"/>
    </div>
    <div class="form-group">
      <input type="text" id="phone" v-model="form.phone" placeholder="Телефон"/>
    </div>
    <div class="form-group">
      <label for="photo" class="file-label">Выберите фотографию</label>
      <input type="file" id="photo" @change="handlePhotoChange" accept="image/*" class="file-input"/>
    </div>

    <button type="submit" class="primary-button" :disabled="passwordMismatch">Зарегистрироваться</button>
    <div class="login-link">
      <p>Уже зарегистрированы?
        <router-link to="/login" class="login-link-text">Войти</router-link>
      </p>
    </div>
  </form>
</template>


<script lang="ts">
import { ref, computed, defineComponent, onMounted } from 'vue';
import AuthApi from "@/api/AuthApi";
import { ApiErrorResponse } from "@/api/base/BaseApi";
import { AxiosError } from "axios";
import router from "@/router/router";
import { CookieManager } from "@/api/base/CookieManager";
import Snackbar from "@/components/common/Snackbar.vue";
import { components } from "user-types";

type RegistrationRequest = components["schemas"]["UserRegistrationRequest"];
type RegistrationRequestFormData = {
  photo: File;
  user: Omit<RegistrationRequest, 'photo'>;
};

export default defineComponent({
  setup() {
    const form = ref<RegistrationRequest>({
      firstname: '',
      lastname: '',
      surname: '',
      username: '',
      email: '',
      phone: '',
      country: 'WITHOUT_COUNTRY',
      password: '',
      confirmPassword: '',
    });

    const photo = ref<File | null>(null);
    const showPassword = ref(false);
    const showError = ref(false);
    const showSuccess = ref(false);
    const errorMessage = ref('');
    const successMessage = ref('');
    const countries = ['RUSSIA', 'USA', 'TURKEY', 'BELARUS', 'INDIA', 'CHINA', 'BRAZIL', 'JAPAN', 'SOUTH_KOREA', 'MEXICO', 'UK', 'GERMANY', 'FRANCE', 'CANADA', 'AUSTRALIA', 'ITALY', 'SPAIN', 'ARGENTINA', 'NIGERIA', 'SOUTH_AFRICA', 'WITHOUT_COUNTRY'];

    const handlePhotoChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        photo.value = target.files[0];
      }
    };

    const passwordMismatch = computed(() => form.value.password !== form.value.confirmPassword);
    const authApi = new AuthApi();

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    const register = async () => {
      if (!photo.value) {
        errorMessage.value = 'Пожалуйста, добавьте фотографию.';
        showError.value = true;
        return;
      }

      if (passwordMismatch.value) return;

      const requestData: RegistrationRequestFormData = {
        photo: photo.value as File,
        user: { ...form.value }
      };

      try {
        const response = await authApi.signUpUser(requestData);
        if (response.token) {
          CookieManager.setCookie('token', response.token);
          successMessage.value = 'Регистрация прошла успешно';
          showSuccess.value = true;
          setTimeout(() => {
            router.push({ name: 'home' });
          }, 1000);
        }
      } catch (error: unknown) {
        const axiosError = error as AxiosError<ApiErrorResponse>;
        errorMessage.value = axiosError.response?.data?.message || 'Неизвестная ошибка. Попробуйте снова.';
        showError.value = true;
      }
    };

    const countryMapping: { [key: string]: "WITHOUT_COUNTRY" | "RUSSIA" | "USA" | "TURKEY" | "BELARUS" | "INDIA" | "CHINA" | "BRAZIL" | "JAPAN" | "SOUTH_KOREA" | "MEXICO" | "UK" | "GERMANY" | "FRANCE" | "CANADA" | "AUSTRALIA" | "ITALY" | "SPAIN" | "ARGENTINA" | "NIGERIA" | "SOUTH_AFRICA" | "SWEDEN" | "NORWAY" | "DENMARK" | "FINLAND" | "SWITZERLAND" | "NETHERLANDS" | "POLAND" | "GREECE" | "PORTUGAL" | "ISRAEL" | "SAUDI_ARABIA" | "UAE" | "SINGAPORE" | "MALAYSIA" | "PHILIPPINES" | "THAILAND" | "VIETNAM" | "NEW_ZEALAND" } = {
      'RU': 'RUSSIA',
      'US': 'USA',
      'TR': 'TURKEY',
      'BY': 'BELARUS',
      'IN': 'INDIA',
      'CN': 'CHINA',
      'BR': 'BRAZIL',
      'JP': 'JAPAN',
      'KR': 'SOUTH_KOREA',
      'MX': 'MEXICO',
      'GB': 'UK',
      'DE': 'GERMANY',
      'FR': 'FRANCE',
      'CA': 'CANADA',
      'AU': 'AUSTRALIA',
      'IT': 'ITALY',
      'ES': 'SPAIN',
      'AR': 'ARGENTINA',
      'NG': 'NIGERIA',
      'ZA': 'SOUTH_AFRICA',
      'SE': 'SWEDEN',
      'NO': 'NORWAY',
      'DK': 'DENMARK',
      'FI': 'FINLAND',
      'CH': 'SWITZERLAND',
      'NL': 'NETHERLANDS',
      'PL': 'POLAND',
      'GR': 'GREECE',
      'PT': 'PORTUGAL',
      'IL': 'ISRAEL',
      'SA': 'SAUDI_ARABIA',
      'AE': 'UAE',
      'SG': 'SINGAPORE',
      'MY': 'MALAYSIA',
      'PH': 'PHILIPPINES',
      'TH': 'THAILAND',
      'VN': 'VIETNAM',
      'NZ': 'NEW_ZEALAND',
    };


    const defaultCountry = 'WITHOUT_COUNTRY' as "WITHOUT_COUNTRY";

    const fetchCountryByIP = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const countryCode = data.country_code?.toUpperCase() || '';
        console.log(countryCode);
        form.value.country = (countryMapping[countryCode] || defaultCountry) as RegistrationRequest['country'];
      } catch {
        form.value.country = defaultCountry as RegistrationRequest['country'];
      }
    };

    onMounted(fetchCountryByIP);

    return {
      form,
      photo,
      showPassword,
      togglePassword,
      handlePhotoChange,
      register,
      passwordMismatch,
      showError,
      showSuccess,
      errorMessage,
      successMessage,
      countries,
    };
  },
  components: {
    Snackbar,
  }
});
</script>


<style scoped>
h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

input, select {
  width: 100%;
  padding: 15px;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input::placeholder {
  color: #ccc;
}

.file-label {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ff7e1b;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.file-input {
  display: none;
}

.primary-button {
  width: 100%;
  padding: 15px;
  background-color: #ff7e1b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.primary-button:hover {
  background-color: #e66a00;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

.login-link {
  text-align: center;
  margin-top: 20px;
}

.login-link-text {
  color: #ff7e1b;
  font-weight: bold;
}

.login-link-text:hover {
  text-decoration: underline;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.eye-container {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.eye {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff7e1b;
}

.eye::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.eye-open::before {
  transform: translate(-50%, -50%) scale(0);
}

.eye-closed::before {
  transform: translate(-50%, -50%) scale(1);
}
</style>