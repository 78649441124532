import { BaseApi } from "@/api/base/BaseApi";

const API_PREFIX = '/backend'

export class ChordApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async saveChords(chords: File[], needUpdate: boolean): Promise<string[]> {
        try {
            const formData = new FormData();

            chords.forEach((file) => {
                formData.append('chords', file);
            });

            return await this.client.post<string[]>(API_PREFIX + '/chord/batch-add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    needUpdate,
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while saving chords: ${error}`);
        }
    }
}

export default new ChordApi();
